import React, { useEffect, useRef, useContext } from 'react'
import Button from 'components/button'
import Link from 'components/link'
import { MainContext } from "context/context"

import 'vendor/flickity-as-nav-for/as-nav-for'
import 'flickity/css/flickity.css'

const Flickity = typeof window !== "undefined" ? require("react-flickity-component") : () => null
const fade = typeof window !== "undefined" ? require("flickity-fade") : () => null

export default ({ posts }) => {
	const {
        addInvertedHeaderSection
    } = useContext(MainContext)

    const contentRef = useRef(null)

    const elementRef = useRef(null)
    
    useEffect(() => {
        if(elementRef && elementRef.current){
            addInvertedHeaderSection(elementRef.current)
        }

        setTimeout(() => {
            if(contentRef.current){
                contentRef.current.resize()
            }
        },500)
    }, [elementRef])

    if(!posts || !posts.length){
        return null
    }

    const flickityProps = {
        initialIndex: 0,
        wrapAround: true,
        prevNextButtons: false,
    }

    return (
        <div className="blog-hero" ref={ref => elementRef.current = ref}>
            <div className="container">
                <ul>
                    <li>
                        <div className="blog-hero-text">
                            <Flickity className="blogherotext"
                                options={{
                                    ...flickityProps,
                                    adaptiveHeight: true,
                                    pageDots: true
                                }}
                                flickityRef={flickityRef => contentRef.current = flickityRef}
                                disableImagesLoaded={false}
                            >
                                { posts.map((row, index) => {
                                    const {
                                        title,
                                        excerpt: defaultExcerpt,
                                        categories: {
                                            nodes: categories
                                        },
                                        link,
                                        newsFields: {
                                            excerpt
                                        }
                                    } = row


                                    const _excerpt = excerpt || defaultExcerpt

                                    const category = categories.length ? categories[0] : null

                                    return (
                                        <div className="blog-hero-text-cont" key={index}>
                                            { category &&
                                                <div 
                                                    className="category"
                                                    data-colour={category.slug}
                                                    dangerouslySetInnerHTML={{ __html: category.name }}
                                                />
                                            }
                                            <Link to={link}>
                                                <h2 dangerouslySetInnerHTML={{ __html: title }} />
                                            </Link>
                                            <p dangerouslySetInnerHTML={{ __html: _excerpt }} />
                                            <Button url={link} />
                                        </div>
                                    )
                                })}
                            </Flickity>
                        </div>
                        <div className="blog-hero-image">
                            <Flickity
                                options={{
                                    ...flickityProps,
                                    draggable: false,
                                    pageDots: false,
                                    asNavFor: '.blogherotext',
                                    fade: true,
                                }}
                                disableImagesLoaded={false}
                            >
                                {posts.map((row, index) => {
                                    const {
                                        featuredImage,
                                    } = row

                                    const background = featuredImage ? featuredImage.mediaItemUrl : ''
            
                                    return (
                                        <div 
                                            className="blog-hero-image-img" 
                                            key={index}
                                            style={{
                                                backgroundImage: `url(${ background })`
                                            }}
                                        />
                                    )
                                })}
                            </Flickity>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}