import React, { useState, useRef } from "react"
import { useLazyQuery } from '@apollo/client'
import { useStaticQuery, graphql } from "gatsby"
import { GET_POSTS_BY_CATEGORY } from '../graphql/queries'

import { debounce } from 'lodash'

import SEO from "components/seo"
import BlogHero from "components/blogHero"
import BlogGrid from "components/blogGrid"
import Banner from "components/banner"

export default () => {
    const [selectedCategorySlug, setSelectedCategorySlug] = useState('')
    const [searchTerm, setSearchTerm] = useState('')

    const searchTermRef = useRef(searchTerm)
    searchTermRef.current = searchTerm

    const [getPostsByCategory, { data: postsData, loading: postsLoading }] = useLazyQuery(GET_POSTS_BY_CATEGORY)
    const [overrideLoading, setOverrideLoading] = useState(false)

    const _handleChangeSearchTerm = term => {
        handleChangeCategory(selectedCategorySlug)
    }

    const debouncedHandleChangeSearchTerm = useRef(debounce(_handleChangeSearchTerm, 300))

    const handleChangeSearchTerm = term => {

        debouncedHandleChangeSearchTerm.current(term)
        setSearchTerm(term)
    }
    
    const handleChangeCategory = slug => {
        // Just cause it sometimes goes immediately
        setOverrideLoading(true)

        setTimeout(() => {
            setOverrideLoading(false)
        },500)

        setTimeout(() => {
            getPostsByCategory({
                variables: {
                    categoryName: slug,
                    searchTerm: searchTermRef.current
                },
            })
        },250)

        setSelectedCategorySlug(slug)
    }

    const data = useStaticQuery(graphql`
        fragment PostFragment on WPGraphQL_Post {
            link
            title
            excerpt
            date
            featuredImage {
                mediaItemUrl
            }
            categories {
                nodes {
                    name
                    slug
                }
            }
            tags {
                nodes {
                    name
                    slug
                }
            }
            newsFields {
                excerpt
            }
        }

        query posts {
            siteFields {
                posts {
                    edges {
                        node {
                            ...PostFragment
                        }
                    }
                }
                options {
                    options {
                        featuredPosts {
                            post {
                                ... on WPGraphQL_Post {
                                    ...PostFragment
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const {
        posts: {
            edges: posts
        },
        options: {
            options: {
                featuredPosts = []
            }
        }
    } = data.siteFields

    const postNodesArray = postsData ? postsData.posts.edges : posts

    const _posts = postNodesArray.map(post => post.node)

    const _featuredPosts = featuredPosts ? featuredPosts.map(node => node.post) : []
    const firstFourPosts = _posts.slice(0,4)
    const remainingPosts = _posts.slice(4)

    const isLoading = overrideLoading || postsLoading
    
    const commonProps = {
        handleChangeCategory,
        selectedCategorySlug,
        searchTerm, 
        handleChangeSearchTerm,
        contentVisible: !isLoading
    }

    return (
        <>
            <SEO title='News &amp; Insights' description='Strikeforce - News &amp; Insights.' />
            <div className="blog">
                <BlogHero posts={_featuredPosts} />
                <BlogGrid 
                    posts={firstFourPosts} 
                    showFilter={true} 
                    {...commonProps}
                />
                <Banner 
                    context={'blog'}
                />
                { remainingPosts.length ? 
                    <BlogGrid 
                        posts={remainingPosts} 
                        showFilter={false} 
                        {...commonProps}
                    /> 
                : null }
            </div>
        </>
    )
}
