import React, { useEffect } from "react"
import BlogGridFilter from "components/blogGridFilter"
import BlogGridPost from "components/blogGridPost"
import stickybits from 'stickybits'

export default ({ 
    posts, 
    showFilter, 
    selectedCategorySlug, 
    handleChangeCategory, 
    contentVisible,
    searchTerm, 
    handleChangeSearchTerm
}) => {
    useEffect(() => {
		typeof window != "undefined" && stickybits('.blog-grid-filter > div', {
			stickyBitStickyOffset: 145
		})
	}, [])
    
    return (
        <div className="blog-grid">
            <div className={`container ${ contentVisible ? '' : 'loading'}`}>
                <div className="loader">
                    <div></div>
                </div>
                { showFilter ? 
                    <BlogGridFilter 
                        selectedSlug={selectedCategorySlug} 
                        handleChange={handleChangeCategory} 
                        searchTerm={searchTerm}
                        handleChangeSearchTerm={handleChangeSearchTerm}
                    /> 
                    : 
                    <div className="blog-grid-empty" />
                }
                <ul className={!posts.length ? 'empty' : ''}>
                    { posts.length ?
                        posts.map((row, index) => (
                            <BlogGridPost 
                                post={row}
                                key={index} 
                            />
                        ))
                    :
                        <div className="search-results-empty">
                            <i className="fe fe-alert-triangle" />
                            <div>
                                <h3>
                                    No results.
                                </h3>
                                <span>
                                    Perhaps try a different search term?
                                </span>
                            </div>
                        </div>
                    }
                </ul>
            </div>
        </div>
    )
}