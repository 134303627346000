import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

export default ({ 
    selectedSlug, 
    handleChange,
    searchTerm, 
    handleChangeSearchTerm
}) => {
    const [catsVisible, setcatsVisible] = useState(false)

    const categories = useStaticQuery(graphql`
        query categories {
            siteFields {
                categories {
                nodes {
                        slug
                        name
                    }
                }
            }
        }
    `)

    const toggleCats = () => {
        setcatsVisible(!catsVisible)
    }

    const {
        nodes: cats
    } = categories.siteFields.categories

    const _categories = [{
        slug: '',
        name: 'All Categories'
    }].concat(cats).filter(category => {
        return category.slug !== 'uncategorised'
    })

    return (
        <div className={`blog-grid-filter ${ catsVisible ? 'active' : ''}`}>
            <div>
                <form action="">
                    <input 
                        type="text" 
                        placeholder="Search blog..." 
                        value={searchTerm}
                        onChange={e => handleChangeSearchTerm(e.target.value)}
                    />
                    <button type="submit" />
                </form>
                <div 
                    className="blog-grid-filter-cats-heading" 
                    dangerouslySetInnerHTML={{ __html: 'Topics' }} 
                    onClick={() => toggleCats()} 
                />
                <div className="blog-grid-filter-cats">
                    {_categories.map((row, index) => (
                        <div 
                            data-name={row.slug } 
                            key={index} 
                            className={row.slug === selectedSlug ? 'active' : '' } 
                            onClick={() => handleChange(row.slug)}
                        >
                            <button
                                dangerouslySetInnerHTML={{ __html: row.name}}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
